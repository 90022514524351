import http from "../http";

export const roleList = params => {
  return http({
    method: "get",
    url: `/api/permission/rolelist`,
    params
  });
};
//获取用户权限
export const userpermisson = params => {
  return http({
    method: "get",
    url: `/api/permission/userpermisson`,
    params
  });
};

export const userRoleList = params => {
  return http({
    method: "get",
    url: `/api/permission/rolelistonuseredit`,
    params
  });
};
export const rolePermissionList = () => {
  return http({
    method: "get",
    url: `/api/permission/rolelistonroleedit`
  });
};
export const addRolePermission = data => {
  return http({
    method: "post",
    url: `/api/permission/addrole`,
    data
  });
};
export const removeRole = data => {
  return http({
    method: "delete",
    url: `/api/permission/deleterole`,
    data
  });
};
export const modifyRolePermission = data => {
  return http({
    method: "put",
    url: `/api/permission/modifyrole`,
    data
  });
};
